var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import { connect } from '@/overmind';
import IconSpeedioSpinner from '@/components/Icons/IconSpeedioSpinner.vue';
let default_1 = class extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.showConfirmationModal = false;
    }
    handleDowngradeRequest() {
        this.loading = true;
        this.showConfirmationModal = true;
        this.handleDowngrade();
    }
    async handleDowngrade() {
        const downgradeRes = await Vue.axios.post('/account_plan/notify_change_plan_solicitation', { type: 'downgrade' });
        try {
            if (downgradeRes.status === 200) {
                this.loading = false;
            }
        }
        catch (error) {
            console.log(error);
            this.loading = false;
        }
    }
    handleGoBack() {
        this.$emit('close');
    }
    handleGoSearch() {
        this.$router.push('/search');
    }
};
default_1 = __decorate([
    Component(connect({
        name: 'DonwgradeModal',
        components: {
            IconSpeedioSpinner,
        },
    }))
], default_1);
export default default_1;
