var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.showUpgradeScreen)?_c('div',{staticClass:"upgrade-container"},[_c('div',{staticClass:"upgrade-header"},[_c('h2',[_vm._v("Revolucione seus resultados")]),_c('div',{staticClass:"q-mt-md upgrade-header-subtitle flex",staticStyle:{"gap":"0.5rem","align-items":"center"}},[_c('p',[_vm._v("Planos feitos para startups até empresas listadas na bolsa")]),_c('CashIcon',{staticStyle:{"margin-top":"-1rem"}})],1),_c('ChangePlanBtn',{staticStyle:{"position":"absolute","right":"0","top":"0"},attrs:{"currentPlan":_vm.currentPlan,"showEditPlan":false}})],1),_c('div',{staticClass:"upgrade-periods"},[_c('div',{class:_vm.selectedPeriod == 'annual'
            ? 'plan-period selectedPeriod'
            : 'plan-period',staticStyle:{"width":"207px","height":"38px","display":"flex","align-items":"center","justify-content":"center","gap":"0.5rem"},on:{"click":function($event){return _vm.handlePeriod('annual')}}},[_c('span',[_vm._v("ANUAL")]),_c('DiscountIcon')],1),_c('span',{class:_vm.selectedPeriod == 'monthly'
            ? 'plan-period selectedPeriod'
            : 'plan-period',staticStyle:{"padding":"0 2rem","width":"96px","height":"38px","display":"flex","align-items":"center","justify-content":"center"},on:{"click":function($event){return _vm.handlePeriod('monthly')}}},[_vm._v("\n        MENSAL\n      ")])]),_c('div',{staticClass:"upgrade-plans"},_vm._l((_vm.sortedPlans()),function(plan,index){return _c('div',{key:index,class:plan.name == _vm.selectedPlan
            ? 'upgrade-plan selectedPlan'
            : 'upgrade-plan',on:{"click":function($event){return _vm.handlePlan(plan.name)}}},[(plan.name == 'Profissional' && _vm.selectedPlan == 'Profissional')?_c('div',{staticClass:"most-popular"},[_c('p',[_vm._v("MAIS POPULAR")])]):_vm._e(),_c('p',{staticClass:"plan-name"},[_vm._v(_vm._s(plan.name.toUpperCase()))]),_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end"}},[_c('p',{staticClass:"plan-price"},[_vm._v(_vm._s(_vm.handlePrices(plan.price)))]),(plan.name != 'Premium')?_c('p',{staticClass:"plan-price",staticStyle:{"font-size":"12px"}},[_vm._v("\n            /mês\n          ")]):_vm._e()]),(plan.name == 'Premium')?_c('p',{staticClass:"plan-fidelity",staticStyle:{"height":"20px"}}):_c('p',{staticClass:"plan-fidelity"},[_vm._v("\n          "+_vm._s(_vm.selectedPeriod == 'annual'
              ? 'Fidelidade de 12 meses'
              : 'Sem fidelidade')+"\n        ")]),_c('p',{staticClass:"plan-catch"},[_vm._v(_vm._s(_vm.handleCatchPhrase(plan.name)))]),_c('span',{staticClass:"divider"}),_c('div',{staticClass:"plan-features"},[_c('div',{staticStyle:{"display":"flex","align-items":"flex-start","justify-content":"flex-start","gap":"0.3rem"}},[_c('RocketIcon'),(plan.name != 'Premium')?_c('p',[_c('b',[_vm._v(_vm._s(plan.metadata.export_credits))]),_vm._v(" empresas por mês\n            ")]):_c('p',[_c('b',[_vm._v("Empresas")]),_vm._v(" personalizadas")]),_c('div',[_c('q-icon',{attrs:{"name":"img:/statics/images/info-rounded.svg"}}),_c('Tootltip',{attrs:{"message":plan.name != 'Premium'
                    ? ((plan.metadata.export_credits) + " créditos de consulta e " + (plan.metadata.export_credits) + " créditos de exportação")
                    : "Quantidade de créditos de consulta e exportação personalizados","bgColor":"#9EB3DC","fontSize":"12px","maxWidth":"200px","textAlign":"center"}})],1)],1),_c('div',{staticStyle:{"display":"flex","align-items":"flex-start","justify-content":"flex-start","gap":"0.3rem"}},[_c('UserIcon'),_c('p',[_c('b',[_vm._v(_vm._s(plan.metadata.additional_users))]),_vm._v("\n              "+_vm._s(plan.metadata.additional_users == '1'
                  ? 'usuário'
                  : plan.metadata.additional_users == 'Usuários'
                  ? 'personalizados'
                  : 'usuários')+"\n            ")]),_c('div',[_c('q-icon',{attrs:{"name":"img:/statics/images/info-rounded.svg"}}),_c('Tootltip',{attrs:{"message":plan.name != 'Premium' && plan.metadata.additional_users > 1
                    ? ((plan.metadata.additional_users) + " pessoas da sua equipe terão acesso à plataforma")
                    : plan.name != 'Premium' &&
                      plan.metadata.additional_users == 1
                    ? ((plan.metadata.additional_users) + " pessoa da sua equipe terá acesso à plataforma")
                    : "Pessoas da sua equipe que terão acesso à plataforma","bgColor":"#9EB3DC","fontSize":"12px","maxWidth":"200px","textAlign":"center"}})],1)],1),_c('div',{staticStyle:{"display":"flex","align-items":"flex-start","justify-content":"flex-start","gap":"0.3rem"}},[_c('HeartIcon'),_c('p',[_vm._v(_vm._s(_vm.handleSupportType(plan.metadata.suport_type)))]),_c('div',[_c('q-icon',{attrs:{"name":"img:/statics/images/info-rounded.svg"}}),_c('Tootltip',{attrs:{"message":_vm.handleSupportType(plan.metadata.suport_type) ==
                  'Suporte básico'
                    ? " Suporte por e-mail"
                    : _vm.handleSupportType(plan.metadata.suport_type) ==
                      'Suporte avançado'
                    ? " Suporte por e-mail e por chat"
                    : " Suporte por e-mail, por chat e por telefone","bgColor":"#9EB3DC","fontSize":"12px","maxWidth":"200px","textAlign":"center"}})],1)],1),(plan.name != 'Básico' && plan.name != 'Avançado')?_c('div',[_c('div',{staticStyle:{"display":"flex","align-items":"flex-start","justify-content":"flex-start","gap":"0.3rem"}},[_c('BrainIcon'),_c('p',[_vm._v("Especialista dedicado")]),_c('div',[_c('q-icon',{attrs:{"name":"img:/statics/images/info-rounded.svg"}}),_c('Tootltip',{attrs:{"message":"Acompanhamento dos seus resultados em vendas por um especialista","bgColor":"#9EB3DC","fontSize":"12px","maxWidth":"200px","textAlign":"center"}})],1)],1)]):_vm._e(),_c('div',{staticStyle:{"display":"flex","align-items":"flex-start","justify-content":"flex-start","gap":"0.3rem"}},[_c('LensIcon'),_c('p',[_vm._v("\n              "+_vm._s(plan.name != 'Premium'
                  ? 'Dados avançados'
                  : 'Dados personalizados')+"\n            ")])],1)]),_c('q-btn',{class:plan.name == _vm.selectedPlan
              ? 'select-btn selectedBtn'
              : 'select-btn',staticStyle:{"min-width":"180px"},attrs:{"loading":_vm.loading,"unelevated":""},on:{"click":function($event){return _vm.handleCheckout(
              plan.name,
              _vm.selectedPeriod,
              plan.metadata.export_credits,
              plan.price,
              plan.metadata.additional_users,
              plan.metadata.suport_type,
              plan.id
            )}}},[_vm._v("\n          "+_vm._s(plan.name == 'Premium' ? 'Fale com um especialista' : 'Selecionar')+"\n        ")])],1)}),0),(_vm.showDowngradeModal)?_c('DowngradeModal',{on:{"close":function($event){_vm.showDowngradeModal = false}}}):_vm._e(),(_vm.showUpgradeInterestModal)?_c('UpgradeInterestModal'):_vm._e(),(_vm.showSpecialistModal)?_c('SpecialistModal'):_vm._e(),(_vm.showWarningModal)?_c('WarningModal',{on:{"handleWarningModal":_vm.handleWarningModal}}):_vm._e()],1):_vm._e(),(_vm.showUpgradeScreen)?_c('UpgradeScreen',{attrs:{"upgradeTo":_vm.upgradeTo,"moreUsersSync":_vm.moreUsers,"addMoreUsers":_vm.addMoreUsers,"attributes":_vm.attributes,"showPaymentMethod":_vm.showPaymentMethod,"showContractToSign":_vm.showContractToSign,"subscriptionData":_vm.subscriptionData},on:{"update:moreUsersSync":function($event){_vm.moreUsers=$event},"update:more-users-sync":function($event){_vm.moreUsers=$event},"handleUsers":_vm.handleUsers,"handleShowUpgradeScreen":_vm.handleShowUpgradeScreen,"handleShowContractToSign":_vm.handleShowContractToSign,"handleShowPayment":_vm.handleShowPayment,"handleChangePrice":_vm.handleChangePrice,"close":_vm.handleCancelPlanUpgrade,"setAddMoreUsers":_vm.setAddMoreUsers,"setSubscriptionData":_vm.setSubscriptionData}}):_vm._e(),_c('ContactAndSalesInfoModal'),_c('ReactivationAndReengagementModal')],1)}
var staticRenderFns = []

export { render, staticRenderFns }