var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import { mixpanelTracking } from '@/services/mixpanel';
import { openClickSignEmbed } from '@/statics/clickSign';
import PaymentSuccessBill from './PaymentSuccessBill.vue';
import PaymentSuccessCard from './PaymentSuccessCard.vue';
import axios from 'axios';
Vue.prototype.$axios = axios.create();
let ContractToSign = class ContractToSign extends Vue {
    constructor() {
        super(...arguments);
        this.showBillModal = false;
        this.showCardModal = false;
    }
    async mounted() {
        this.$emit('handleAllowEditing', false);
        const res = await Vue.axios.get('/account_plan/selected');
        if (res.data.contract.signed) {
            this.$emit('handleShowContractToSign', false);
            this.$emit('handleShowPayment', true);
        }
        else {
            this.widget = openClickSignEmbed(this.subscriptionData.contract.request_signature);
            mixpanelTracking('upgrade 2.0: contrato foi renderizado');
            this.widget.on('signed', e => {
                mixpanelTracking('upgrade 2.0: contrato assinado');
                this.signContract();
            });
        }
    }
    unmounted() {
        if (this.widget) {
            this.widget.unmount();
        }
    }
    async signContract() {
        try {
            const res = await Vue.axios.put(`/account_plan/update_signed_contract/${this.subscriptionData.id}`);
            if (res) {
                this.$emit('handleShowContractToSign', false);
                this.$emit('handleShowPayment', true);
            }
        }
        catch {
            mixpanelTracking('upgrade 2.0: erro na assinatura do contrato');
        }
    }
};
__decorate([
    Prop({ default: false })
], ContractToSign.prototype, "subscriptionData", void 0);
ContractToSign = __decorate([
    Component({
        name: 'ContractToSign',
        components: {
            PaymentSuccessBill,
            PaymentSuccessCard,
        },
    })
], ContractToSign);
export default ContractToSign;
