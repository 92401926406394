var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mixpanelTracking } from '@/services/mixpanel';
import TermsOfUse from './TermsOfUse.vue';
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.paymentData = {
            name: '',
            street_address: '',
            complemental_address: '',
            neighborhood: '',
            city: '',
            state: '',
            zipcode: '',
            contractor_document: '',
            contractor_name: '',
            business_name: '',
            house_number: '',
            email: '',
        };
        this.showTerms = false;
        this.termsAccepted = false;
    }
    async beforeMount() {
        this.paymentData.contractor_document =
            localStorage.getItem('contractor_document') ||
                this.attributes.contractor_document ||
                '';
        this.paymentData.name =
            localStorage.getItem('name') || this.attributes.name || '';
        this.paymentData.business_name =
            localStorage.getItem('business_name') ||
                this.attributes.business_name ||
                '';
        this.paymentData.email =
            localStorage.getItem('email') || this.attributes.email || '';
        this.paymentData.street_address =
            localStorage.getItem('street_address') ||
                this.attributes.street_address ||
                '';
        this.paymentData.complemental_address =
            localStorage.getItem('complemental_address') ||
                this.attributes.complemental_address ||
                '';
        this.paymentData.house_number =
            localStorage.getItem('house_number') || this.attributes.house_number || '';
        this.paymentData.zipcode =
            localStorage.getItem('zipcode') || this.attributes.zipcode || '';
        this.paymentData.neighborhood =
            localStorage.getItem('neighborhood') || this.attributes.neighborhood || '';
        this.paymentData.city =
            localStorage.getItem('city') || this.attributes.city || '';
        this.paymentData.state =
            localStorage.getItem('state') || this.attributes.state || '';
    }
    async hendleChageCep() {
        const cep = this.paymentData.zipcode.replace('-', '');
        if (cep.length == 8) {
            const response = await Vue.axios.get(`https://brasilapi.com.br/api/cep/v1/${cep}`);
            if (response.status == 200) {
                this.paymentData.state = response.data.state || this.paymentData.state;
                this.paymentData.neighborhood =
                    response.data.neighborhood || this.paymentData.neighborhood;
                this.paymentData.city = response.data.city || this.paymentData.city;
                this.paymentData.street_address =
                    response.data.street || this.paymentData.street_address;
            }
        }
    }
    handleGoBack() {
        this.handleSaveOnLocalStorage();
        this.handleShowUpgradeScreen(false);
    }
    handleShowUpgradeScreen(show) {
        this.$emit('handleShowUpgradeScreen', show);
    }
    handleSaveOnLocalStorage() {
        localStorage.setItem('contractor_document', this.paymentData.contractor_document);
        localStorage.setItem('name', this.paymentData.name);
        localStorage.setItem('business_name', this.paymentData.business_name);
        localStorage.setItem('email', this.paymentData.email);
        localStorage.setItem('street_address', this.paymentData.street_address);
        localStorage.setItem('complemental_address', this.paymentData.complemental_address);
        localStorage.setItem('house_number', this.paymentData.house_number);
        localStorage.setItem('zipcode', this.paymentData.zipcode);
        localStorage.setItem('neighborhood', this.paymentData.neighborhood);
        localStorage.setItem('city', this.paymentData.city);
        localStorage.setItem('state', this.paymentData.state);
    }
    handleUF() {
        this.paymentData.state = this.paymentData.state.toUpperCase();
    }
    handleTerms() {
        if (!this.showTerms) {
            this.handleSaveOnLocalStorage();
        }
        this.showTerms = !this.showTerms;
    }
    prepareContract() {
        mixpanelTracking('upgrade 2.0: dados contratuais foram preenchidos');
        this.$emit('patchAccountPreferences', this.paymentData);
    }
    handleAllowEditing(allow) {
        this.$emit('handleAllowEditing', allow);
    }
    handleAcceptTerms(accept) {
        this.termsAccepted = accept;
    }
    get handleDisable() {
        const sameFieldEmpty = [
            this.paymentData.contractor_document,
            this.paymentData.name,
            this.paymentData.email,
            this.paymentData.street_address,
            this.paymentData.house_number,
            this.paymentData.zipcode,
            this.paymentData.neighborhood,
            this.paymentData.city,
            this.paymentData.state,
        ].includes('');
        const invalid_cnpj = this.paymentData.contractor_document.replace(/\D/g, '').length < 14;
        return (invalid_cnpj ||
            sameFieldEmpty ||
            this.termsAccepted == false ||
            this.loading);
    }
};
__decorate([
    Prop()
], default_1.prototype, "attributes", void 0);
__decorate([
    Prop({ default: false })
], default_1.prototype, "loading", void 0);
__decorate([
    Watch('paymentData.zipcode')
], default_1.prototype, "hendleChageCep", null);
default_1 = __decorate([
    Component({
        name: 'ContractReview',
        components: {
            TermsOfUse,
        },
    })
], default_1);
export default default_1;
