var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import DotsIcon from '../modules/upgradeplans/icons/DotsIcon.vue';
import CashIcon from '../modules/upgradeplans/icons/CashIcon.vue';
import DiscountIcon from '../modules/upgradeplans/icons/DiscountIcon.vue';
import UserIcon from '../modules/upgradeplans/icons/UserIcon.vue';
import RocketIcon from '../modules/upgradeplans/icons/RocketIcon.vue';
import HeartIcon from '../modules/upgradeplans/icons/HeartIcon.vue';
import BrainIcon from '../modules/upgradeplans/icons/BrainIcon.vue';
import LensIcon from '../modules/upgradeplans/icons/LensIcon.vue';
import DowngradeModal from '../modules/upgradeplans/DowngradeModal.vue';
import UpgradeInterestModal from '../modules/upgradeplans/UpgradeInterestModal.vue';
import SpecialistModal from '../modules/upgradeplans/SpecialistModal.vue';
import WarningModal from '../modules/upgradeplans/WarningModal.vue';
import UpgradeScreen from '../modules/upgradeplans/UpgradeScreen.vue';
import ChangePlanBtn from '../modules/upgradeplans/ChangePlanBtn.vue';
import Tootltip from '@/components/Tooltip.vue';
import { mixpanelTracking } from '@/services/mixpanel';
import axios from 'axios';
import ContactAndSalesInfoModal from '@/components/ContactAndSalesInfoModal/ContactAndSalesInfoModal.vue';
import ReactivationAndReengagementModal from '@/components/ReactivationAndReengagementModal/ReactivationAndReengagementModal.vue';
Vue.prototype.$axios = axios.create();
let UpgradePlans = class UpgradePlans extends Vue {
    constructor() {
        super(...arguments);
        this.monthlyPlans = [];
        this.annualPlans = [];
        this.userCredits = 0;
        this.currentPlan = '';
        this.selectedPeriod = '';
        this.selectedPlan = '';
        this.showOptions = false;
        this.showDowngradeModal = false;
        this.showUpgradeInterestModal = false;
        this.showSpecialistModal = false;
        this.showWarningModal = false;
        this.showUpgradeScreen = false;
        this.showContractToSign = false;
        this.showPaymentMethod = false;
        this.loading = false;
        this.moreUsers = 0;
        this.addMoreUsers = false;
        this.upgradeTo = {
            name: '',
            period: '',
            credits: 0,
            plan_value: 0,
            users: '',
            support: '',
            id: '',
        };
        this.subscriptionData = {
            contract: {
                contract_id: '',
                request_signature: '',
                signed: false,
            },
            id: '',
        };
        this.attributes = {
            account_id: '',
            account_type: '',
            apps: [],
            business_name: '',
            categories: [],
            contractor_document: '',
            name: '',
            country: '',
            email: '',
            id: '',
            keywords_available: {},
            phone_number: '',
            skip_origin_check: false,
            superlogica_account_id: '',
            street_address: '',
            complemental_address: '',
            house_number: '',
            zipcode: '',
            neighborhood: '',
            city: '',
            state: '',
        };
        this.handleSortPlans = plan => {
            return plan.sort((a, b) => {
                const clickCreditsA = parseInt(a.metadata.click_credits);
                const clickCreditsB = parseInt(b.metadata.click_credits);
                if (clickCreditsA < clickCreditsB) {
                    return -1;
                }
                else if (clickCreditsA > clickCreditsB) {
                    return 1;
                }
                else {
                    return 0;
                }
            });
        };
    }
    async beforeMount() {
        const res = await Vue.axios.get('/account_plan/selected');
        if (res.data) {
            this.upgradeTo = {
                name: res.data.name || '',
                period: res.data.recurrence || '',
                credits: res.data.click_credits || '',
                plan_value: res.data.initial_price || null,
                users: res.data.users || null,
                support: res.data.suport || '',
                id: res.data.selected_subscription_id || '',
            };
            this.moreUsers = res.data.more_users || null;
            this.addMoreUsers = res.data.more_users > 0 ? true : false;
            this.subscriptionData = {
                contract: {
                    contract_id: res.data.contract.contract_id,
                    request_signature: res.data.contract.request_signature,
                    signed: res.data.contract.signed,
                },
                id: res.data.selected_subscription_id,
            };
            if (res.data.contract && res.data.contract.signed == false) {
                this.handleShowUpgradeScreen(true);
                this.handleShowContractToSign(true);
            }
            if (res.data.contract && res.data.contract.signed == true) {
                this.handleShowUpgradeScreen(true);
                this.handleShowPayment(true);
            }
        }
    }
    async mounted() {
        mixpanelTracking('upgrade 2.0: página foi renderizada');
        const userRes = await Vue.axios.get('account_plan/current');
        this.currentPlan = userRes.data.plan.name;
        this.userCredits = userRes.data.plan.credits.exports;
        const plansRes = await Vue.axios.get('account_plan/list_available_plans');
        this.monthlyPlans = plansRes.data.Mensal;
        this.annualPlans = plansRes.data.Anual;
        this.selectedPeriod = 'annual';
        this.selectedPlan = 'Profissional';
        this.addCustomPlans();
    }
    addCustomPlans() {
        this.annualPlans.push({
            id: '',
            metadata: {
                additional_users: 'Usuários',
                click_credits: '99999',
                dedicated_specialist: true,
                export_credits: 'Empresas',
                suport_type: 'premium',
            },
            name: 'Premium',
            price: 'Fale conosco',
            recurrence: 'Anual',
        });
        this.monthlyPlans.push({
            id: '',
            metadata: {
                additional_users: 'Usuários',
                click_credits: '99999',
                dedicated_specialist: true,
                export_credits: 'Empresas',
                suport_type: 'premium',
            },
            name: 'Premium',
            price: 'Fale conosco',
            recurrence: 'Mensal',
        });
    }
    setSubscriptionData(data) {
        this.subscriptionData.contract = data.contract;
        this.subscriptionData.id = data.id;
    }
    sortedPlans() {
        return this.selectedPeriod === 'annual'
            ? this.handleSortPlans([...this.annualPlans])
            : this.handleSortPlans([...this.monthlyPlans]);
    }
    handleShowPayment(show) {
        this.showPaymentMethod = show;
    }
    handleShowContractToSign(show) {
        this.showContractToSign = show;
    }
    handlePrices(price) {
        return price == 'Fale conosco' ? 'Fale conosco' : this.formatPrice(price);
    }
    formatPrice(price) {
        const formattedPrice = Number(price) > 999 ? (Number(price) / 1000).toFixed(3) : price;
        return `R$${formattedPrice},00`;
    }
    handleCatchPhrase(plan) {
        switch (plan) {
            case 'Básico':
                return 'Perfeito para você começar a alavancar seu negócio';
            case 'Avançado':
                return 'Destaque-se no excepcional para seu ramo';
            case 'Profissional':
                return 'Para quem busca aprimoramento e resultados superiores';
            case 'Enterprise':
                return 'Máximo desempenho para grandes empresas dominarem o mercado';
            case 'Premium':
                return 'Plano personalizado de acordo com a necessidade do seu negócio';
            default:
                return '';
        }
    }
    handleSupportType(type) {
        switch (type) {
            case 'basic':
                return 'Suporte básico';
            case 'advanced':
                return 'Suporte avançado';
            case 'premium':
                return 'Suporte premium';
            default:
                return '';
        }
    }
    handleUsers(type) {
        if (type == 'plus') {
            this.moreUsers++;
        }
        else {
            if (this.moreUsers > 0) {
                this.moreUsers--;
            }
        }
    }
    setAddMoreUsers(value) {
        this.addMoreUsers = value;
    }
    handlePeriod(period) {
        this.selectedPeriod = period;
    }
    handlePlan(plan) {
        this.selectedPlan = plan;
    }
    handleCheckout(plan, period, credits, value, users, support, id) {
        if (plan == 'Premium') {
            this.loading = true;
            return this.handleSpecialistModal();
        }
        this.loading = true;
        const desiredPlan = plan.toLowerCase();
        const desiredPeriod = period == 'annual' ? 'anual' : 'mensal';
        const desiredCredits = Number(credits);
        const planValue = Number(value);
        mixpanelTracking(`upgrade 2.0: plano ${desiredPlan} ${desiredPeriod} foi selecionado`);
        if (this.currentPlan != 'Free' && this.userCredits < desiredCredits) {
            return this.handleUpgradeInterestModal();
        }
        if (this.currentPlan != 'Free' && this.userCredits > desiredCredits) {
            return this.handleDowngradeModal();
        }
        if (this.currentPlan != 'Free' && this.userCredits == desiredCredits) {
            return this.handleWarningModal();
        }
        if (this.currentPlan == 'Free' && this.userCredits < desiredCredits) {
            return this.handlePlanUpgrade(desiredPlan, desiredPeriod, desiredCredits, planValue, users, support, id);
        }
    }
    handlePlanUpgrade(plan, period, credits, value, users, support, id) {
        this.upgradeTo = {
            name: plan,
            period: period,
            credits: credits,
            plan_value: value,
            users: users,
            support: support,
            id: id,
        };
        this.getAccountPreferences();
    }
    async getAccountPreferences() {
        try {
            const res = await Vue.axios.get('/account_preferences');
            this.attributes = res.data.data.attributes;
            this.loading = false;
            this.handleShowUpgradeScreen(true);
        }
        catch (error) {
            error instanceof Error ? console.error(error.message) : null;
        }
    }
    handleShowUpgradeScreen(value) {
        this.showUpgradeScreen = value;
    }
    handleCancelPlanUpgrade() {
        mixpanelTracking("upgrade 2.0: clicou em 'Voltar' na tela de Revisão dos dados contratuais");
        this.handleShowUpgradeScreen(false);
    }
    handleSpecialistModal() {
        mixpanelTracking("upgrade 2.0: 'Falar com um especialista' foi selecionado");
        this.showSpecialistModal = true;
        this.loading = false;
    }
    handleUpgradeInterestModal() {
        mixpanelTracking('upgrade 2.0: usuário tem interesse em plano maior ao atual');
        this.showUpgradeInterestModal = true;
        this.loading = false;
    }
    handleWarningModal() {
        this.showWarningModal = !this.showWarningModal;
        this.loading = false;
    }
    handleDowngradeModal() {
        mixpanelTracking("upgrade 2.0: 'Downgrade de plano' foi selecionado'");
        this.showDowngradeModal = true;
        this.loading = false;
    }
    handleChangePrice(name, newPeriod) {
        if (newPeriod == 'anual') {
            this.getPriceByName(name, newPeriod, this.annualPlans);
        }
        else {
            this.getPriceByName(name, newPeriod, this.monthlyPlans);
        }
    }
    getPriceByName(name, newPeriod, data) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].name.toLowerCase() == name) {
                //@ts-ignore
                this.upgradeTo = {
                    ...this.upgradeTo,
                    period: newPeriod,
                    plan_value: Number(data[i].price),
                    id: data[i].id,
                };
            }
        }
        return null;
    }
};
UpgradePlans = __decorate([
    Component({
        name: 'UpgradePlans',
        components: {
            DotsIcon,
            CashIcon,
            DiscountIcon,
            UserIcon,
            RocketIcon,
            HeartIcon,
            BrainIcon,
            LensIcon,
            Tootltip,
            DowngradeModal,
            SpecialistModal,
            UpgradeScreen,
            ChangePlanBtn,
            UpgradeInterestModal,
            WarningModal,
            ContactAndSalesInfoModal,
            ReactivationAndReengagementModal,
        },
    })
], UpgradePlans);
export default UpgradePlans;
