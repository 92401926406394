var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import { connect } from '@/overmind';
import { mixpanelTracking } from '@/services/mixpanel';
import axios from 'axios';
import IconBoleto from '@/components/Icons/IconBoleto.vue';
import IconCardDefault from '@/components/Icons/CreditCardFlags/IconCardDefault.vue';
import IconCheckedNoBorder from '@/components/Icons/IconCheckedNoBorder.vue';
import EditPaymentMethod from '@/modules/subscriptions/components/EditPaymentMethod.vue';
import PaymentSuccessBill from './PaymentSuccessBill.vue';
import PaymentSuccessCard from './PaymentSuccessCard.vue';
Vue.prototype.$axios = axios.create();
let SelectPayment = class SelectPayment extends Vue {
    constructor() {
        super(...arguments);
        this.showContractToSign = false;
        this.hasCard = false;
        this.loading = false;
        this.showBillModal = false;
        this.showCardModal = false;
    }
    async mounted() {
        this.$emit('handleAllowEditing', false);
        mixpanelTracking('upgrade 2.0: seleção de forma de pagamento foi renderizado');
    }
    handleMethod(paymentMethod) {
        this.$emit('handleSelectedMethod', paymentMethod);
    }
    async registerBankSlip() {
        this.loading = true;
        this.handleMethod('boleto');
        try {
            const res = await Vue.axios.put('/payment/payment_method', {
                type: 'bank_slip',
                fast_plan_upgrade: true,
            });
            res ? (this.loading = false) : null;
        }
        catch (error) {
            error instanceof Error
                ? mixpanelTracking(`upgrade 2.0: falha ao registrar o boleto - ${error.message}`)
                : null;
        }
    }
    async registerCreditCard(card) {
        this.loading = true;
        mixpanelTracking('upgrade 2.0: tentativa de registro do cartão de crédito');
        try {
            const data = { card, type: 'credit_card' };
            const changeMethod = await Vue.axios.put('/payment/payment_method', data);
            if (changeMethod) {
                this.hasCard = true;
                mixpanelTracking('upgrade 2.0: cartão de crédito registrado com sucesso');
            }
            else {
                mixpanelTracking('upgrade 2.0: falha ao registrar o cartão de crédito');
            }
        }
        catch (error) {
            error instanceof Error
                ? mixpanelTracking(`upgrade 2.0: erro ao criar token de cartão - ${error.message}`)
                : null;
        }
        this.loading = false;
    }
    async finishPurchase() {
        this.loading = true;
        try {
            const res = await Vue.axios.put('/account_plan/finish_update', {
                additional_users: this.addMoreUsers ? this.moreUsers : 0,
                origin: 'Upgrade 2.0',
            });
            this.loading = false;
            //@ts-ignore
            res.status == 200 ? this.openModal(this.selectedMethod) : null;
        }
        catch (error) {
            error instanceof Error
                ? (mixpanelTracking(`upgrade 2.0: Finalização de compra falhou - ${error.message}`),
                    (this.loading = false))
                : null;
        }
    }
    openModal(selectedMethod) {
        selectedMethod == 'boleto'
            ? (this.showBillModal = true)
            : (this.showCardModal = true);
    }
    handleDisable() {
        return ((this.selectedMethod == 'card' && !this.hasCard) || !this.selectedMethod);
    }
};
__decorate([
    Prop({ default: '' })
], SelectPayment.prototype, "selectedMethod", void 0);
__decorate([
    Prop({ default: 0 })
], SelectPayment.prototype, "moreUsers", void 0);
__decorate([
    Prop({ default: false })
], SelectPayment.prototype, "addMoreUsers", void 0);
SelectPayment = __decorate([
    Component(connect({
        name: 'SelectPayment',
        components: {
            IconBoleto,
            IconCardDefault,
            IconCheckedNoBorder,
            PaymentSuccessBill,
            PaymentSuccessCard,
            EditPaymentMethod,
        },
    }))
], SelectPayment);
export default SelectPayment;
