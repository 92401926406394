var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Watch, PropSync } from 'vue-property-decorator';
import { connect } from '@/overmind';
import Tootltip from '@/components/Tooltip.vue';
import TooltipIcon from '@/modules/upgradeplans/icons/TooltipIcon.vue';
import ContractReview from '@/modules/upgradeplans/ContractReview.vue';
import SelectPayment from './SelectPayment.vue';
import ContractToSign from './ContractToSign.vue';
import { mixpanelTracking } from '@/services/mixpanel';
import axios from 'axios';
Vue.prototype.$axios = axios.create();
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.expandOptions = false;
        this.editable = true;
        this.selectedMethod = '';
        this.loading = false;
        this.isAdd = false;
    }
    handleInvalidInput() {
        this.moreUsers < 0 ? (this.moreUsers = 0) : null;
    }
    handleExpandOptions() {
        this.expandOptions = !this.expandOptions;
    }
    addUsers(type) {
        this.$emit('handleUsers', type);
    }
    handleChangePrice() {
        let newPeriod = this.upgradeTo.period == 'anual' ? 'mensal' : 'anual';
        mixpanelTracking(`upgrade 2.0: alterou de ${this.upgradeTo.period} para ${newPeriod}`);
        this.$emit('handleChangePrice', this.upgradeTo.name, newPeriod);
        this.handleExpandOptions();
    }
    handleShowUpgradeScreen(value) {
        this.$emit('handleShowUpgradeScreen', value);
    }
    handleAllowEditing(value) {
        this.editable = value;
    }
    handleSelectedMethod(method) {
        this.selectedMethod = method;
    }
    handleShowPayment(show) {
        this.$emit('handleShowPayment', show);
    }
    handleShowContractToSign(show) {
        this.$emit('handleShowContractToSign', show);
    }
    setLoading(value) {
        this.loading = value;
    }
    async patchAccountPreferences(data) {
        this.setLoading(true);
        try {
            await Vue.axios.patch('/account_preferences', data);
            await this.createContractSubscription();
        }
        catch (error) {
            error instanceof Error
                ? mixpanelTracking(`upgrade 2.0: erro ao salvar dados de contrato - ${error.message}`)
                : null;
        }
        this.setLoading(false);
    }
    async createContractSubscription() {
        try {
            const res = await Vue.axios.post(`/account_plan/select_plan_to_update/${this.upgradeTo.id}?additional_users=${this.moreUsers}`);
            this.$emit('setSubscriptionData', {
                contract: res.data.contract,
                id: res.data.selected_subscription_id,
            });
            this.handleShowContractToSign(true);
        }
        catch (error) {
            error instanceof Error
                ? mixpanelTracking(`upgrade 2.0: erro ao criar contrato - ${error.message}`)
                : null;
        }
    }
    handleSupport() {
        const support = this.upgradeTo.support;
        if (!support || support == 'basic') {
            return 'Suporte básico: e-mail';
        }
        if (support == 'advanced') {
            return 'Suporte avançado: e-mail e chat';
        }
        if (support == 'premium') {
            return 'Suporte premium: e-mail, chat e telefone';
        }
    }
    trackUsersToggle() {
        this.addMoreUsers == false
            ? mixpanelTracking('upgrade 2.0: opção de adicionar usuários adicionais desativada')
            : mixpanelTracking('upgrade 2.0: opção de adicionar usuários adicionais ativada');
    }
    trackIsAdd() {
        this.isAdd == false
            ? this.$emit('setAddMoreUsers', false)
            : this.$emit('setAddMoreUsers', true);
    }
};
__decorate([
    Prop({ default: false })
], default_1.prototype, "upgradeTo", void 0);
__decorate([
    Prop({ default: false })
], default_1.prototype, "showContractToSign", void 0);
__decorate([
    Prop({ default: false })
], default_1.prototype, "showPaymentMethod", void 0);
__decorate([
    Prop({ default: false })
], default_1.prototype, "addMoreUsers", void 0);
__decorate([
    PropSync('moreUsersSync', { default: 0 })
], default_1.prototype, "moreUsers", void 0);
__decorate([
    Prop({ default: false })
], default_1.prototype, "subscriptionData", void 0);
__decorate([
    Prop()
], default_1.prototype, "attributes", void 0);
__decorate([
    Watch('addMoreUsers')
], default_1.prototype, "trackUsersToggle", null);
__decorate([
    Watch('isAdd')
], default_1.prototype, "trackIsAdd", null);
default_1 = __decorate([
    Component(connect({
        name: 'UpgradeScreen',
        components: {
            Tootltip,
            TooltipIcon,
            ContractReview,
            SelectPayment,
            ContractToSign,
        },
    }))
], default_1);
export default default_1;
